exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-fisheries-tsx": () => import("./../../../src/pages/fisheries.tsx" /* webpackChunkName: "component---src-pages-fisheries-tsx" */),
  "component---src-pages-hero-tsx": () => import("./../../../src/pages/hero.tsx" /* webpackChunkName: "component---src-pages-hero-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../../../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-t-tsx": () => import("./../../../src/pages/t.tsx" /* webpackChunkName: "component---src-pages-t-tsx" */),
  "component---src-templates-fishery-tsx": () => import("./../../../src/templates/fishery.tsx" /* webpackChunkName: "component---src-templates-fishery-tsx" */)
}

